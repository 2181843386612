import BooksRoot from "../components/booksroot";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { normalize_graphql_data } from "../components/utils";

const BookRoot = ({ data, pageContext }) => {
  let target_books = normalize_graphql_data(data.target.nodes);
  let other_books = normalize_graphql_data(data.other_books.edges);
  let other_people = normalize_graphql_data(data.other_people.edges);

  let paginationData = {
    currentPage: pageContext.currentPage,
    numPages: pageContext.numPages,
  };

  return (
    <Layout>
      <BooksRoot
        books={target_books}
        other_people={other_people}
        other_books={other_books}
        pagination={paginationData}
      />
    </Layout>
  );
};

export const query = graphql`
  query BookRootPaginationQuery($skip: Int!, $limit: Int!) {
    other_people: allPeepsJson(limit: 12, sort: { fields: person_ranking }) {
      edges {
        node {
          ...PeepsJsonFragment
        }
      }
    }
    other_books: allBeeksJson(
      skip: 30
      limit: 12
      sort: { fields: recommended_volume, order: DESC }
    ) {
      edges {
        node {
          ...BeeksJsonFragment
        }
      }
    }
    target: allBeeksJson(
      limit: $limit
      skip: $skip
      sort: { fields: name, order: ASC }
    ) {
      totalCount
      nodes {
        ...BeeksJsonFragmentComplete
      }
    }
  }
`;

export default BookRoot;
